/* Styles go here */

.header{
    cursor: pointer;
    border: solid 1px #f2f2f2;
    padding: 15px;
    background-color: #0089CC;
    color: #FFF;
    font-family: verdana;
  }
  
  .content{
    cursor: pointer;
    border-left: solid 1px #f2f2f2;
    border-right: solid 1px #f2f2f2;
    border-bottom: solid 1px #f2f2f2;
    border-radius: 0 0 5px 5px;
    padding: 5px;
    font-family: verdana;
    font-size: 12px;
  }