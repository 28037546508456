.xfield {
    width: 100%;
    height: 26px;
    border-radius: 4px;
    position: relative;
    background-color: rgba(255,255,255,0.3);
    transition: 0.3s all;
  }
  .xfield:hover {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }

  .xfield.focussed input {
    padding: 24px 16px 8px 16px;
  }
  .xfield.focussed input + label {
    top: 4px;
    opacity: 1;
    color: #28cc70;
  }
  .xfield.locked {
   pointer-events: none;
  }
  .xfield input {
    width: 100%;
    height: 26px;
    position: relative;
    padding: 0px 4px;
    border-radius: 4px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
  }
